<template>
  <super-admin-page-card pageName="Copy Queries" iconClass="fa fa-clone fa-lg">
    <div>
      <b-tabs v-model="tabIndex">
        <b-tab>
          <template slot="title">
            <h6 class="tab-title font-weight-bold">
              Copy Queries
            </h6>
          </template>

          <v-wait for="loadingNonNavigatorQueries">
            <template slot="waiting">
              <content-placeholders :rounded="true" class="pt-4">
                <content-placeholders-text
                  :lines="20"
                ></content-placeholders-text>
              </content-placeholders>
            </template>

            <div>
              <div class="row px-2">
                <div class="col-sm-12 text-left">
                  <small>
                    <b>
                      <sup class="">* </sup>
                      NOTE:
                    </b>
                    Copy will not update existing query, it will create a
                    duplicate copy for the new group.
                  </small>
                </div>
              </div>
            </div>
            <div class="p-3">
              <div class="row">
                <div class="col-sm-6">
                  <div>
                    <b-form-group
                      label="Query"
                      label-for="query"
                      :label-cols="3"
                      :horizontal="true"
                      class=""
                    >
                      <multiselect
                        v-model="selectedQuery"
                        :options="nonNavigatorQueries"
                        :multiple="false"
                        :close-on-select="true"
                        :showLabels="false"
                        track-by="id"
                        label="name"
                        placeholder="Select query to share"
                        :class="[
                          'simple-select group-select highlight-group-title form-control rounded'
                        ]"
                        @select="fetchQueryGroupsData"
                      >
                      </multiselect>
                    </b-form-group>

                    <b-form-group
                      label="Group"
                      label-for="group"
                      :label-cols="3"
                      :horizontal="true"
                      class=""
                    >
                      <multiselect
                        v-model="selectedGroup"
                        :options="availableGroups"
                        :multiple="false"
                        :close-on-select="true"
                        :showLabels="false"
                        :loading="fetchingGroups"
                        track-by="id"
                        label="name"
                        placeholder="Select a group to share with"
                        :class="[
                          'simple-select group-select highlight-group-title form-control rounded'
                        ]"
                      >
                      </multiselect>
                    </b-form-group>
                  </div>
                </div>

                <div class="col-sm-6 pl-5" v-if="selectedQuery">
                  <div class="row" v-if="selectedQuery.fi_type == 'mbc'">
                    <b-card
                      class="std-border mb-3 rounded card--report-schedule"
                      body-class="p-2 schedule-body"
                      header-class="p-2 schedule-header"
                    >
                      <div>
                        <b>{{ selectedQuery.name }}</b> is an <b>MBC</b> query.
                        Please make sure the shared group has appropriate access
                        to view the selected query.
                      </div>
                    </b-card>
                  </div>
                  <div class="row">
                    <b-card
                      class=" col-sm-12 px-0 std-border mb-3 rounded card--report-schedule"
                      body-class="p-2 schedule-body"
                      header-class="p-2 schedule-header"
                    >
                      <div slot="header">
                        <div class="row fit-to-center">
                          <div class="col-sm-12">
                            <b> Shared Groups </b>
                          </div>
                        </div>
                      </div>

                      <v-wait for="loadingQueryGroups">
                        <template slot="waiting">
                          <content-placeholders :rounded="true" class="pt-4">
                            <content-placeholders-text
                              :lines="5"
                            ></content-placeholders-text>
                          </content-placeholders>
                        </template>
                        <div v-if="sharedGroups.length">
                          <p>
                            <b>{{ selectedQuery.name }}</b> currently is shared
                            among
                          </p>
                          <b-list-group>
                            <b-list-group-item
                              v-for="(group, index) in sharedGroups"
                              :key="index"
                            >
                              {{ group.name }} ({{ group.id }})
                            </b-list-group-item>
                          </b-list-group>
                        </div>
                        <div v-else>
                          <p>
                            <b>{{ selectedQuery.name }}</b> currently is not
                            shared among it's group.
                          </p>
                        </div>
                      </v-wait>
                    </b-card>
                  </div>
                </div>
              </div>

              <div class="row pt-3">
                <div class="col-6 d-flex justify-content-center">
                  <div class="px-1">
                    <button
                      type="button"
                      class="btn fw-btn btn-secondary rounded"
                      @click="resetQueryShare"
                    >
                      Reset
                    </button>
                  </div>
                  <div class="px-1">
                    <button
                      type="button"
                      class="btn fw-btn btn-primary rounded"
                      @click="copyQuery"
                    >
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </v-wait>
        </b-tab>
        <b-tab>
          <template slot="title">
            <h6 class="tab-title font-weight-bold">
              Copy Queries to Users
            </h6>
          </template>

          <v-wait for="loadingNonNavigatorQueries">
            <template slot="waiting">
              <content-placeholders :rounded="true" class="pt-4">
                <content-placeholders-text
                  :lines="20"
                ></content-placeholders-text>
              </content-placeholders>
            </template>

            <div>
              <div class="row px-2">
                <div class="col-sm-12 text-left">
                  <small>
                    <b>
                      <sup class="">* </sup>
                      NOTE:
                    </b>
                    Copy will not update existing query, it will create a
                    duplicate copy for the new user.
                  </small>
                </div>
              </div>
            </div>
            <div class="p-3">
              <div class="row">
                <div class="col-sm-6">
                  <div>
                    <b-form-group
                      label="Query"
                      label-for="query"
                      :label-cols="3"
                      :horizontal="true"
                      class=""
                    >
                      <multiselect
                        v-model="selectedQuery"
                        :options="nonNavigatorQueries"
                        :multiple="false"
                        :close-on-select="true"
                        :showLabels="false"
                        track-by="id"
                        label="name"
                        placeholder="Select query to share"
                        :class="[
                          'simple-select group-select highlight-group-title form-control rounded'
                        ]"
                        @select="fetchQueryUsersData"
                      >
                      </multiselect>
                    </b-form-group>

                    <b-form-group
                      label="User"
                      label-for="user"
                      :label-cols="3"
                      :horizontal="true"
                      class=""
                    >
                      <Multiselect
                        v-model="selectedUser"
                        :options="availableUsers"
                        :multiple="false"
                        :close-on-select="true"
                        :showLabels="false"
                        :loading="fetchingUsers"
                        track-by="id"
                        label="email"
                        placeholder="Select a user to share with"
                        :class="[
                          'simple-select group-select highlight-group-title form-control rounded'
                        ]"
                      >
                      </Multiselect>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-sm-6 pl-5" v-if="selectedQuery">
                  <div class="row" v-if="selectedQuery.fi_type == 'mbc'">
                    <b-card
                      class="std-border mb-3 rounded card--report-schedule"
                      body-class="p-2 schedule-body"
                      header-class="p-2 schedule-header"
                    >
                      <div>
                        <b>{{ selectedQuery.name }}</b> is an <b>MBC</b> query.
                        Please make sure the shared user has appropriate access
                        to view the selected query.
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>

              <div class="row pt-3">
                <div class="col-6 d-flex justify-content-center">
                  <div class="px-1">
                    <button
                      type="button"
                      class="btn fw-btn btn-secondary rounded"
                      @click="resetQueryShare"
                    >
                      Reset
                    </button>
                  </div>
                  <div class="px-1">
                    <button
                      type="button"
                      class="btn fw-btn btn-primary rounded"
                      @click="copyQuery"
                    >
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </v-wait>
        </b-tab>
      </b-tabs>
    </div>
  </super-admin-page-card>
</template>

<script>
// ui components
import Multiselect from 'vue-multiselect'
// api
import advancedQuriesAPI from '@/api/finapps/super_admin/advanced_queries'
import userAPI from '@/api/finapps/super_admin/users'

export default {
  name: 'SuperAdminCopyQueries',
  components: {
    Multiselect
  },
  data() {
    return {
      tabIndex: 0,
      nonNavigatorQueries: [],
      fetchingGroups: false,
      selectedQuery: null,
      selectedGroup: null,
      sharedGroups: [],
      availableGroups: [],
      fetchingUsers: false,
      selectedUser: null,
      availableUsers: [],
      allUsers: []
    }
  },
  created() {
    this.loadNonNavigatorQueries()
  },
  computed: {},
  methods: {
    loadNonNavigatorQueries() {
      this.$wait.start('loadingNonNavigatorQueries')

      advancedQuriesAPI.nonNavigatorQueries().then(res => {
        this.nonNavigatorQueries = res.non_navigator_queries
        this.$wait.end('loadingNonNavigatorQueries')
      })
    },
    fetchQueryGroupsData(query) {
      this.fetchingGroups = true
      this.$wait.start('loadingQueryGroups')

      advancedQuriesAPI.groupDetails(query.id).then(res => {
        this.sharedGroups = res.shared_groups
        this.availableGroups = res.sharable_groups
        this.fetchingGroups = false
        this.$wait.end('loadingQueryGroups')
      })
    },
    fetchQueryUsersData(query) {
      this.fetchingUsers = true
      this.$wait.start('loadingQueryUsers')
      userAPI.allUsers().then(res => {
        this.allUsers = res.users
        this.availableUsers = this.allUsers.filter(
          user => user.id != query.user_id
        )
        this.fetchingUsers = false
        this.$wait.end('loadingQueryUsers')
      })
    },
    copyQuery() {
      advancedQuriesAPI
        .copyQuery(
          this.selectedQuery.id,
          this.selectedGroup ? this.selectedGroup.id : null,
          this.selectedUser ? this.selectedUser.id : null
        )
        .then(
          () => {
            this.$toasted.global.action_success('Query copied successfully.')
            this.tabIndex = this.selectedGroup ? 0 : 1
            this.resetQueryShare()
            this.loadNonNavigatorQueries()
          },
          () => {
            this.tabIndex = this.selectedGroup ? 0 : 1
            this.selectedGroup = null
            this.selectedUser = null
          }
        )
    },
    resetQueryShare() {
      this.selectedQuery = null
      this.selectedGroup = null
      this.sharedGroups = []
      this.availableGroups = []
      this.selectedUser = null
      this.availableUsers = []
      this.allUsers = []
    }
  }
}
</script>
<style lang="scss" scoped></style>
