import Vue from 'vue'
import axios from 'axios'

export default {
  groups() {
    return axios
      .get('/api/super_admin/advanced_queries/navigator_queries', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  updateAccess(groupID, isPublic) {
    return axios
      .put(
        `/api/super_admin/advanced_queries/${groupID}`,
        {
          is_public: isPublic
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data
      })
  },
  updateOrder(publicGroups) {
    return axios
      .put(
        '/api/super_admin/advanced_queries/update_order',
        {
          advanced_queries: publicGroups.map(pg => ({
            id: pg.id,
            query_order: pg.query_order
          }))
        },
        { handleErrors: true }
      )
      .then(() => {})
  },
  nonNavigatorQueries() {
    return axios
      .get('/api/super_admin/advanced_queries/non_navigator_queries', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  groupDetails(queryID) {
    return axios
      .get(`/api/super_admin/advanced_queries/${queryID}/group_details`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  copyQuery(queryID, groupID, userID) {
    return axios
      .get(`/api/super_admin/advanced_queries/${queryID}/copy_query`, {
        params: {
          group_id: groupID,
          user_id: userID
        },
        handleErrors: true
      })
      .then(
        res => {
          return res.data
        },
        error => {
          if (error.response.status === 422) {
            Vue.toasted.show(
              'Validation failed. Query already exists for selected user/group.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
          return Promise.reject(error)
        }
      )
  }
}
